import { useOrganization } from "../useOrganization";
import { usePostHog } from "posthog-js/react";
import debounce from "lodash/debounce";
import { useState } from "react";
import { ExtractEventPayload, AnalyticsEvents } from "./analytics.events";
import { analyticsEnabled, analyticsEnvironment } from "~config";

interface UseAnalyticsEventsOptions {
  debounceTime: number;
}

const DEFAULT_OPTIONS: UseAnalyticsEventsOptions = {
  // Default debounce time is 500ms - avoids repeated events being
  // dispatched, useful when events are triggered in effects
  debounceTime: 500,
};

export const useAnalyticsEvents = (
  opts?: Partial<UseAnalyticsEventsOptions>
) => {
  const [eventSent, setEventSent] = useState(false);
  const posthog = usePostHog();
  const { organization } = useOrganization();

  const options = opts ? { ...DEFAULT_OPTIONS, ...opts } : DEFAULT_OPTIONS;

  // Debounce the event capture to avoid sending multiple events in quick succession
  // Depending value passed in postHogEventType, the payload will be validated
  // to ensure it at least conforms to the expected shape
  const captureEvent = debounce(
    <T extends AnalyticsEvents>(
      posthogEventType: T,
      payload: ExtractEventPayload<T>,
      config?: { exactlyOnce: boolean }
    ) => {
      if (!organization) return;
      if (config?.exactlyOnce && eventSent) return;
      if (!analyticsEnabled) return;
      setEventSent(true);
      posthog.capture(posthogEventType, {
        ...payload,
        flicket_organization_id: organization.id,
        flicket_organization_name: organization.name,
        flicket_organization_slug: organization.slug,
        flicket_platform: "customer_portal",
        flicket_environment: analyticsEnvironment,
      });
    },
    options.debounceTime
  );

  return { captureEvent };
};
